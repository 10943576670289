import logo from './assets/images/latera-rc-arena.png';
import video from './assets/images/masthead.mp4';

import './App.css';
import 'fomantic-ui-css/semantic.min.css'

import { Button, Grid, Header, Image, Segment } from 'semantic-ui-react'

function App() {
  const handleVideoLoad = (event) => {
    const video = event.target;
    video.muted = true;
    video.loop = true;
    video.play();
  }

  return (
    <>
      <video className='video' onCanPlayThrough={handleVideoLoad}>
        <source src={video} type="video/mp4" />
      </video>
      <Grid columns={3} >
        <Grid.Row stretched style={{ height: '100vh' }} verticalAlign='middle' >
          <Grid.Column mobile={2} computer={5}>
          </Grid.Column>
          <Grid.Column mobile={12} computer={6}>
            <Grid stackable relaxed columns='equal' stretched verticalAlign='middle' textAlign='center'>
              <Grid.Row stretched>
                <Grid.Column>
                  <Segment inverted style={{ backgroundColor: 'rgba(32, 32, 32, 0.8)' }}>
                    <Image src={logo} size='large' centered />
                    <Header as='h2'>
                      Radiostyrd bilsport
                      <Header.Subheader>
                        <a href="https://goo.gl/maps/eu1RNb3d5ST6suZG8" target="_blank" rel="noreferrer">Arntorpsgatan 6, Kungälv</a>
                      </Header.Subheader>
                    </Header>
                    <p><i>Under sommarhalvåret är banan öppen en dag i veckan. Fr.o.m i höst har vi öppet flera dagar i veckan igen.</i></p>
                    <p>Onsdag 16:00 - 19:00</p>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row stretched>
                <Grid.Column>
                  <Button size='huge' color='red' as='a' onClick={() => document.getElementById('hur').scrollIntoView({ behavior: 'smooth' })}>Hur funkar det?</Button>
                </Grid.Column>
                <Grid.Column>
                  <Button size='huge' color='red' as='a' onClick={() => document.getElementById('var').scrollIntoView({ behavior: 'smooth' })}>Hitta hit</Button>
                </Grid.Column>
              </Grid.Row> */}
            </Grid>
          </Grid.Column>
          <Grid.Column mobile={2} computer={5}>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* <Segment inverted>
        <Header as='h2' id='hur'>
          Hur funkar det?
        </Header>
        Är du nybörjare och vill prova har vi hyrbilar för 200kr 
        <Header as='h3'>
          Egen bil
        </Header>
        <Header as='h3'>
          Hyra bil
        </Header>
        <Header as='h2' id='var'>
          Hitta hit
        </Header>
        <a href="https://goo.gl/maps/eu1RNb3d5ST6suZG8" target="_blank" rel="noreferrer">Google Maps</a>
      </Segment> */}
    </>
  );
}

export default App;
